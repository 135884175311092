import { render, staticRenderFns } from "./NewTHGCO2Equivalent.vue?vue&type=template&id=142d16dd"
import script from "./NewTHGCO2Equivalent.vue?vue&type=script&setup=true&lang=ts"
export * from "./NewTHGCO2Equivalent.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_t3gmm35n6tkn624j6aeupb7v5y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports