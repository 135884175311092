import { del, get, HttpResponse, patch, post } from '@/api/Api';
import { api_url } from '@/api/thg/api';
import { TokenType } from '@/enums/TokenType';
import { THGCO2Equivalent, THGUpdateCO2EquivalentData } from '@/interfaces/thg/THGCO2Equivalent';
import { parseISO } from 'date-fns';

/**
 * Retrieve a CO2 Equivalent.
 *
 * @param id
 * @return http response to await
 */
export async function fetchTHGCO2Equivalent(id: string): Promise<HttpResponse<THGCO2Equivalent>> {
    const res = await get<THGCO2Equivalent>(
        `${api_url()}/crm/co2-equivalents/${id}`,
        TokenType.THG_API_KEY,
    );

    if (res.parsedBody?.created_at) {
        res.parsedBody.created_at = parseISO(res.parsedBody.created_at as string);
    }

    if (res.parsedBody?.updated_at) {
        res.parsedBody.updated_at = parseISO(res.parsedBody.updated_at as string);
    }

    return res;
}

/**
 * Retrieve all CO2 equivalents.
 *
 * @return list of co2 equivalents
 */
export async function fetchTHGCO2Equivalents(): Promise<HttpResponse<THGCO2Equivalent[]>> {
    return await get<THGCO2Equivalent[]>(`${api_url()}/crm/co2-equivalents`, TokenType.THG_API_KEY);
}

/**
 * Add a CO2 equivalent.
 *
 * @param data
 */
export async function addTHGCO2Equivalent(
    data: THGCO2Equivalent,
): Promise<HttpResponse<THGCO2Equivalent>> {
    return await post<THGCO2Equivalent>(
        `${api_url()}/crm/co2-equivalents`,
        data,
        TokenType.THG_API_KEY,
    );
}

/**
 * Update a CO2 Equivalent.
 *
 * @param id
 * @param data update data
 */
export async function saveTHGCO2Equivalent(
    id: string,
    data: THGUpdateCO2EquivalentData,
): Promise<HttpResponse<THGCO2Equivalent>> {
    return patch<THGCO2Equivalent>(
        `${api_url()}/crm/co2-equivalents/${id}`,
        data,
        TokenType.THG_API_KEY,
    );
}

/**
 * Delete a CO2 Equivalent.
 *
 * @param id
 * @return http response to await
 */
export async function deleteTHGCO2Equivalent(id: string): Promise<HttpResponse<null>> {
    return await del<null>(`${api_url()}/crm/co2-equivalents/${id}`, null, TokenType.THG_API_KEY);
}
