import { DialogProgrammatic as Dialog } from 'buefy';
import { BComponent } from 'buefy/types/components';

/**
 * Show an error alert modal window.
 *
 * @param title
 * @param text
 */
export function alertError(title: string, text: string) {
    Dialog.alert({
        type: 'is-danger',
        title: title,
        message: text,
        hasIcon: true,
        icon: 'close-circle',
        iconPack: 'mdi',
        ariaRole: 'alertdialog',
        ariaModal: true,
    });
}

/**
 * Show an info alert window.
 *
 * @param title
 * @param text
 */
export function alertInfo(title: string, text: string) {
    Dialog.alert({
        type: 'is-success',
        title: title,
        message: text,
        hasIcon: true,
        icon: 'check-circle',
        iconPack: 'mdi',
        ariaRole: 'alertdialog',
        ariaModal: true,
        confirmText: 'OK',
    });
}

interface ConfirmResult {
    result: boolean;
    dialog: BComponent;
}

/**
 * Show a confirm window.
 *
 * @param title
 * @param question
 * @param confirmText
 * @param type bulma color schema
 */
export function confirm(
    title: string,
    question: string,
    confirmText: string,
    type = 'is-info',
): Promise<ConfirmResult> {
    // @ts-ignore
    return Dialog.confirm({
        type: type,
        title: title,
        cancelText: 'Abbrechen',
        confirmText: confirmText,
        message: question,
        hasIcon: true,
        closeOnConfirm: false,
    });
}

interface NumberPromptResult {
    result: number;
    dialog: BComponent;
}

/**
 * Show a number prompt window.
 *
 * @param message
 * @param placeholder input's placeholder
 * @param min minimal number
 * @param max maximal number
 * @param step
 */
export function numberPrompt(
    message: string,
    placeholder: string,
    min: number,
    max: number,
    step = 1,
): Promise<NumberPromptResult> {
    // @ts-ignore
    return Dialog.prompt({
        message: message,
        inputAttrs: {
            type: 'number',
            placeholder: placeholder,
            min: min,
            max: max,
            step: step,
        },
        trapFocus: true,
    });
}
